import { getApiHost } from '../utils/utils';

export async function loginUser(credentials) {
  const host = await getApiHost(credentials.uid)
  return fetch(`${host}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'https://app.neuralens.ai',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

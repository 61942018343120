import React, { useState } from "react";
import Login from "./components/Login/Login";
import useAuth from "./components/useAuth";
import { loginUser } from "./api/login";
import SearchComponent from "./components/Search";
import ConfigManager from "./components/ConfigManager";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  CssBaseline,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NLLogoFullWhiteSmall from "./images/NLLogoFullWhiteSmall.png";

const theme = createTheme();

const App = () => {
  const { authData, setAuthData, resetAuthData } = useAuth();
  const [currentView, setCurrentView] = useState("search");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = () => {
    resetAuthData();
    handleMenuClose();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderContent = () => {
    switch (currentView) {
      case "search":
        return <SearchComponent token={authData.token} user={authData.user} />;
      case "config":
        return <ConfigManager token={authData.token} user={authData.user} />;
      default:
        return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {!authData.token && (
          <Login setAuthData={setAuthData} loginUser={loginUser} />
        )}
        {authData.token && (
          <>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  <img
                    src={NLLogoFullWhiteSmall}
                    style={{ width: "140px" }}
                    alt="logo"
                  />
                </Typography>
                <Button
                  color="inherit"
                  onClick={() => setCurrentView("search")}
                  variant={currentView === "search" ? "outlined" : "text"}
                >
                  Search
                </Button>
                {!["rentomojo_test", "neuralens_furniture_demo"].includes(
                  authData.user
                ) && (
                  <Button
                    color="inherit"
                    onClick={() => setCurrentView("config")}
                    variant={currentView === "config" ? "outlined" : "text"}
                  >
                    Config Manager
                  </Button>
                )}

                <div>
                  <Button
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    endIcon={<AccountCircle />}
                  >
                    {authData.user}
                  </Button>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
              </Toolbar>
            </AppBar>
            <Container maxWidth="lg" sx={{ mt: 4 }}>
              {renderContent()}
            </Container>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export default App;

import { getApiHost } from '../utils/utils'

export async function fetchSuggestions(user, token, body) {
    let apiHost = await getApiHost(user);
    const response = await fetch(`${apiHost}/search/autocomplete`, {
        headers: {
            "X-Neuralens-Api-Key": token,
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': 'https://app.neuralens.ai',
        },
        method: "POST",
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        throw new Error('Failed to fetch suggestions');
    }
    const res = await response.json();
    if (res?.results) {
        console.log("fetchSuggestions response: ", res, [...(res.results.text || []), ...(res.results.product || [])])
        return [...(res.results.text || []), ...(res.results.product || [])]
    }
    return [];
}

export async function fetchSearchResults(user, token, body) {
    let apiHost = await getApiHost(user);
    const response = await fetch(`${apiHost}/search/query`, {
        headers: {
            "X-Neuralens-Api-Key": token,
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': 'https://app.neuralens.ai'
        },
        method: "POST",
        body: JSON.stringify(body)
    });
    if (!response.ok) {
		console.log(response);
        throw new Error('Failed to fetch search results');
    }
    return response.json();
}

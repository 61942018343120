import React, { useState, useMemo, useCallback } from 'react';
import {
  Box, Chip, FormControl, TextField, MenuItem,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const toSentenceCase = (str) => {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/[_-]/g, ' ')
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    .trim();
};

const GenericSelector = ({ label, fields, value, onChange, type }) => {
  const [open, setOpen] = useState(false);
  const [selectedField, setSelectedField] = useState('');
  const [labels, setLabels] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState(null);

  const sortedFields = useMemo(() =>
    fields.sort((a, b) => a.localeCompare(b)),
    [fields]
  );

  const filteredFields = useMemo(() =>
    sortedFields.filter(field =>
      field.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (type === 'multi' ? !value.includes(field) : !value.some(v => v.field === field))
    ),
    [sortedFields, searchTerm, value, type]
  );

  const handleAdd = useCallback(() => {
    if (selectedField) {
      if (type === 'multi') {
        if (!value.includes(selectedField)) {
          onChange([...value, selectedField]);
        }
      } else if (!value.some(v => v.field === selectedField)) {
        const newItem = { field: selectedField, ...labels };
        onChange([...value, newItem]);
      } else {
        alert('This field has already been added.');
        return;
      }
      setSelectedField('');
      setLabels({});
      setOpen(false);
      setSearchTerm('');
    }
  }, [selectedField, labels, value, onChange, type]);

  const handleEdit = useCallback((index) => {
    if (type === 'multi') return; // No editing for multi-select
    const item = value[index];
    setSelectedField(item.field);
    setLabels(type === 'filter' ? { label: item.label } : { asc_label: item.asc_label, desc_label: item.desc_label });
    setEditIndex(index);
    setOpen(true);
  }, [value, type]);

  const handleUpdate = useCallback(() => {
    if (type === 'multi') return; // No updating for multi-select
    if (selectedField && Object.values(labels).every(label => label)) {
      const newValue = [...value];
      newValue[editIndex] = { field: selectedField, ...labels };
      onChange(newValue);
      setSelectedField('');
      setLabels({});
      setEditIndex(-1);
      setOpen(false);
    }
  }, [selectedField, labels, value, editIndex, onChange, type]);

  const handleDeleteConfirm = useCallback(() => {
    if (type === 'multi') {
      onChange(value.filter(item => item !== fieldToDelete));
    } else {
      onChange(value.filter(item => item.field !== fieldToDelete));
    }
    setDeleteConfirmOpen(false);
    setFieldToDelete(null);
  }, [value, onChange, type, fieldToDelete]);
  const handleDeleteClick = useCallback((field) => {
    setFieldToDelete(field);
    setDeleteConfirmOpen(true);
  }, []);
  const handleSelectField = useCallback((field) => {
    setSelectedField(field);
    if (type !== 'multi') {
      const sentenceCase = toSentenceCase(field);
      if (type === 'filter') {
        setLabels({ label: sentenceCase });
      } else {
        setLabels({ asc_label: `Ascending ${sentenceCase}`, desc_label: `Descending ${sentenceCase}` });
      }
      setOpen(true);
    } else {
      onChange([...value, field]);
    }
    setSearchTerm('');
  }, [type, onChange, value]);

  return (
    <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 1, p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Box component="span" sx={{ fontWeight: 'bold' }}>
          {label}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {type === 'multi'
          ? value.map((field) => (
              <Chip
                key={field}
                label={field}
                onDelete={() => handleDeleteClick(field)}
              />
            ))
          : value.map((v, index) => (
              <Chip
                key={v.field}
                label={type === 'filter' ? `${v.field} (${v.label})` : `${v.field} (${v.asc_label}/${v.desc_label})`}
                onDelete={() => handleDeleteClick(v.field)}
                onClick={(event) => {
                  event.preventDefault();
                  handleEdit(index);
                }}
              />
            ))
        }
      </Box>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <TextField
          select
          value={searchTerm}
          onChange={(e) => {
            console.log("onchange", e, e?.target?.value);
            const value = e.target.value;
            if (filteredFields.includes(value)) {
              handleSelectField(value);
            } else {
              setSearchTerm(value);
            }
          }}
          placeholder={`Search or select ${type === 'multi' ? 'options' : type}`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250,
                },
              },
            },
          }}
        >
          {filteredFields.map((field) => (
            <MenuItem key={field} value={field}>{field}</MenuItem>
          ))}
        </TextField>
      </FormControl>
      {type !== 'multi' && (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>{editIndex === -1 ? `Add ${type}` : `Edit ${type}`}</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Field"
              value={selectedField}
              InputProps={{ readOnly: true }}
              sx={{ mt: 2 }}
            />
            {type === 'filter' ? (
              <TextField
                fullWidth
                label="Label"
                value={labels.label || ''}
                onChange={(e) => setLabels({ label: e.target.value })}
                sx={{ mt: 2 }}
              />
            ) : (
              <>
                <TextField
                  fullWidth
                  label="Ascending Label"
                  value={labels.asc_label || ''}
                  onChange={(e) => setLabels({ ...labels, asc_label: e.target.value })}
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  label="Descending Label"
                  value={labels.desc_label || ''}
                  onChange={(e) => setLabels({ ...labels, desc_label: e.target.value })}
                  sx={{ mt: 2 }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={editIndex === -1 ? handleAdd : handleUpdate}>
              {editIndex === -1 ? 'Add' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete "{fieldToDelete}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const FilterSelector = (props) => <GenericSelector {...props} type="filter" />;
const SortSelector = (props) => <GenericSelector {...props} type="sort" />;
const MultiSelector = (props) => <GenericSelector {...props} type="multi" />;

export { FilterSelector, SortSelector, MultiSelector };
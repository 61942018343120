import React, { useState } from 'react';
import './Login.css';
import PropTypes from 'prop-types';
import NLLogo from "../../images/logo-full-small.png";

export default function Login({setAuthData, loginUser}) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await loginUser({
      "uid": username,
      "pwd": password
    });
    if (response.token) {
      if(!response.user) {
        response.user = username;
      }
      setAuthData(response);
    } else {
      alert("Login failed");
    }
  }

  return(
    <div className="login-wrapper">
        <img src={NLLogo} alt="logo" style={{width: "200px", marginBottom: "48px"}}/>
        <div className="login-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit} class="mt-4">
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={e => setUserName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">Log In</button>
        </form>
      </div>
    </div>
  )
}

Login.propTypes = {
  setAuthData: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired
}

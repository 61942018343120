import { useState } from 'react';

export const getAuthData = () => {
  try {
    return JSON.parse(localStorage.getItem('auth')) || {};
  } catch (error) {
    console.error(error);
    return {};
  }
};

export default function useAuth() {
  const [authData, setAuthData] = useState(getAuthData());

  const saveAuthData = authData => {
    localStorage.setItem('auth', JSON.stringify(authData));
    setAuthData(authData);
  };
  const resetAuthData = () => {
    localStorage.removeItem('auth');
    setAuthData({});
  };
  return {
    setAuthData: saveAuthData,
    resetAuthData,
    authData
  }
}
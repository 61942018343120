import { getApiHost } from '../utils/utils'

export async function generateConfig(user, token, sampleDocs) {
    let apiHost = await getApiHost(user);
    const response = await fetch(`${apiHost}/search/config/generate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Neuralens-Api-Key': token,
            'Access-Control-Allow-Origin': 'https://app.neuralens.ai',
        },
        body: JSON.stringify(sampleDocs),
    });
    return response.json();
}

export async function getConfig(user, token) {
    let apiHost = await getApiHost(user);
    const response = await fetch(`${apiHost}/search/config`,
        {
            headers: {
                'Content-Type': 'application/json',
                'X-Neuralens-Api-Key': token,
                'Access-Control-Allow-Origin': 'https://app.neuralens.ai',
            }
        },
    );
    return response.json();
}

export async function updateConfig(user, token, data) {
    let apiHost = await getApiHost(user);
    const response = await fetch(`${apiHost}/search/config/update`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'X-Neuralens-Api-Key': token,
            'Access-Control-Allow-Origin': 'https://app.neuralens.ai',
        },
        body: JSON.stringify(data),
    });
    return response.json();
}

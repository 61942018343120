import config from '../config';

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export async function getApiHost(user) {
	return (["rentomojo_test", "neuralens_furniture_demo"].includes(user) && config.apiHost.includes('neuralens.ai')) ? 'http://api2.neuralens.ai' : config.apiHost;
}

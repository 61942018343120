import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { fetchSearchResults, fetchSuggestions } from '../api/search';

const SearchContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 1200,
  margin: '2rem auto',
  padding: '0 1rem',
  fontFamily: theme.typography.fontFamily,
}));

const SearchInputWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  // borderRadius: 24,
  overflow: 'hidden',
  transition: 'all 0.3s',
}));

const SearchInput = styled(TextField)({
  flexGrow: 1,
  // '& .MuiInputBase-root': {
  //   padding: '2px 2px 2px 2px',
  // },
  // '& .MuiInputBase-input': {
  //   padding: '10px 12px',
  // },
});

const SearchButton = styled(Button)(({ theme }) => ({
  // borderRadius: 0,
  // padding: '10px 24px',
}));

const SuggestionList = styled(List)(({ theme }) => ({
  marginTop: theme.spacing(1),
  maxHeight: 200,
  overflowY: 'auto',
}));

const ResultGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const PlaceholderImage = ({ width, height }) => (
  <Box
    component="svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={width} height={height} fill="#e0e0e0" />
    <text
      x="50%"
      y="50%"
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#9e9e9e"
      fontSize={16}
      fontFamily="Arial, sans-serif"
    >
      No Image
    </text>
  </Box>
);

const SearchComponent = ({ token, user }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(true);
  // const [offset, setOffset] = useState(0);
  const [nextOffset, setNextOffset] = useState(null);
  const [total, setTotal] = useState(0);
  const limit = 20; // Number of results per page
  const isOtipy = (user) => ["otipy", "otipy_prod", "otipy_stage"].includes(user.toLowerCase());
  const getCommonRequestBody = (user) => {
    const body = {}
    if (isOtipy(user)) {
      body.filters = [{
        "field": "is_active",
        "values": ["true"]
      }, {
        "field": "is_visible",
        "values": ["true"]
      }]
      body.sorts = [
        {
          "field": "out_of_stock",
          "order": "asc"
        }
      ]
      body.fields = ["title", "images","out_of_stock"]
      body.partition = "1"
    }
    return body
  }
  const handleSearch = useCallback(async (query, offset = 0) => {
    setIsLoading(true);
    setError('');
    try {
      const body = {q: query, limit, offset, fields: ["title", "images", "image"], ...getCommonRequestBody(user)};
      const results = await fetchSearchResults(user, token, body);
      if (isOtipy(user)) {
        for (let i = 0; i < results.results.length; i++) {
          if (results.results[i].images) {
            for (let j = 0; j < results.results[i].images.length; j++) {
              if (results.results[i].images[j].startsWith("http")) {
                continue;
              }
              results.results[i].images[j] = `https://www.otipy.com/_next/image?url=https%3A%2F%2Fimg.crofarm.com%2Fimages%2Fprodsmall%2F${results.results[i].images[j]}&w=256&q=75"`
            }
          }

        }
      }
      setSearchResults(prevResults => offset === 0 ? results.results : [...prevResults, ...results.results]);
      setNextOffset(results.pagination.next_offset);
      setTotal(results.pagination.total);
      // setOffset(offset);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setError('Failed to fetch search results. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    const getSuggestions = async () => {
      if (query.length > 2 && shouldFetchSuggestions) {
        setIsLoading(true);
        setError('');
        try {
          const body = { q: query, limit: 5 , ...getCommonRequestBody(user)};
          const results = await fetchSuggestions(user, token, body);
          const suggestions = results.map(({ title }) => title);
          setSuggestions(suggestions);
        } catch (error) {
          console.error('Error fetching suggestions:', error);
          setError('Failed to fetch suggestions. Please try again.');
        } finally {
          setIsLoading(false);
        }
      } else {
        setSuggestions([]);
      }
    };

    const debounceTimer = setTimeout(getSuggestions, 300);
    return () => clearTimeout(debounceTimer);
  }, [query, token]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedSuggestionIndex(prev =>
        prev < suggestions.length - 1 ? prev + 1 : prev
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedSuggestionIndex(prev => prev > 0 ? prev - 1 : -1);
    } else if (e.key === 'Enter') {
      setShouldFetchSuggestions(false);
      if (selectedSuggestionIndex >= 0) {
        setQuery(suggestions[selectedSuggestionIndex]);
        handleSearch(suggestions[selectedSuggestionIndex]);
      } else {
        handleSearch(query);
      }
      setSuggestions([]);
      setSelectedSuggestionIndex(-1);
    }
  };
  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    setShouldFetchSuggestions(true);
  };
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    handleSearch(suggestion);
    setSuggestions([]);
    setShouldFetchSuggestions(false);
  };

  const loadMoreResults = () => {
    if (nextOffset !== null && nextOffset < total) {
      handleSearch(query, nextOffset);
    }
  };

  return (
    <SearchContainer>
      <Typography variant="h4" gutterBottom>Search</Typography>
      <SearchInputWrapper elevation={1}>
        <SearchInput
          fullWidth
          value={query}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
          placeholder="Search..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && <CircularProgress size={20} />}
              </InputAdornment>
            ),
          }}
        />
        <SearchButton
          variant="contained"
          onClick={() => {
            handleSearch(query);
            setShouldFetchSuggestions(false);
          }}
          disabled={isLoading}
        >
          <SearchIcon />
        </SearchButton>
      </SearchInputWrapper>
      {error && (
        <Typography color="error" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}
      {suggestions.length > 0 && shouldFetchSuggestions && (
        <SuggestionList>
          {suggestions.map((suggestion, index) => (
            <ListItem
              key={index}
              button
              onClick={() => handleSuggestionClick(suggestion)}
              selected={index === selectedSuggestionIndex}
            >
              <ListItemText primary={suggestion} />
            </ListItem>
          ))}
        </SuggestionList>
      )}
      {searchResults.length > 0 && (
        <>
          <ResultGrid container spacing={2}>
            {searchResults.map((result, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card>
                  {(result.image || result.images) ? (
                    <CardMedia
                      component="img"
                      height="200"
                      image={result.image || result.images[0]}
                      alt={result.title}
                    />
                  ) : (
                    <PlaceholderImage width={268} height={200} />
                  )}
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {result.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </ResultGrid>
          {nextOffset !== null && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button
                variant="contained"
                onClick={loadMoreResults}
                disabled={isLoading || nextOffset >= total}
              >
                Load More
              </Button>
            </Box>
          )}
          <Typography sx={{ mt: 2, textAlign: 'center' }}>
            Showing {searchResults.length} of {total} results
          </Typography>
        </>
      )}
    </SearchContainer>
  );
};

export default SearchComponent;

import React, { useState, useEffect } from 'react';
import {
  Box, Tabs, Tab, Typography, TextField, Button, Paper, Alert,
  Select, MenuItem, FormControl, InputLabel, OutlinedInput,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent,
  DialogActions, CircularProgress, Snackbar
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { SortSelector, FilterSelector, MultiSelector } from './Selector';
// import MultiSelector from './MultiSelector';
import { generateConfig, getConfig, updateConfig } from '../api/config';

const fieldTypes = [
  'text', 'integer', 'float', 'boolean',
  'list of text', 'list of integer', 'list of float', 'list of boolean'
];

const FieldComponent = ({ field, onFieldChange, onDeleteField }) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    onDeleteField();
    setDeleteConfirmOpen(false);
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <TextField
        label="Field Name"
        value={field.name}
        onChange={(e) => onFieldChange({ ...field, name: e.target.value })}
        sx={{ mr: 2 }}
      />
      <FormControl sx={{ minWidth: 200, mr: 2 }}>
        <InputLabel>Field Type</InputLabel>
        <Select
          value={field.type}
          onChange={(e) => onFieldChange({ ...field, type: e.target.value })}
          input={<OutlinedInput label="Field Type" />}
        >
          {fieldTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        color="error"
        onClick={handleDeleteClick}
        sx={{
          height: '56px',
          minWidth: '56px',
          padding: '6px 16px',
        }}
      >
        <Delete />
      </Button>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this "{field.name}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};


const ConfigManager = ({ user, token }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [config, setConfig] = useState(null);
  const [editedConfig, setEditedConfig] = useState(null);
  const [sampleDocs, setSampleDocs] = useState('[{"id": "123", "title": "Title", "price": 100.2, "images":["im1.jpg"]}]');
  const [errors, setErrors] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] = useState(false);


  useEffect(() => {
    if (editedConfig && config) {
      setUnsavedChanges(JSON.stringify(editedConfig) !== JSON.stringify(config));
    }
  }, [editedConfig, config]);



  useEffect(() => {
    const handleGetConfig = async () => {
      try {
        const data = await getConfig(user, token);
        if (data.status === "failure") {
          setErrors(data.errors || [data.message]);
          return;
        }
        setConfig(data.config);
        setEditedConfig(data.config);
        setErrors([]);
      } catch (error) {
        console.error(error);
        setErrors(['Failed to fetch config']);
      }
    };
    handleGetConfig();
  }, [token]);

  const handleUpdateConfig = async () => {
    try {
      const data = await updateConfig(user, token, editedConfig);
      if (data.status === "failure") {
        setErrors(data.errors || [data.message]);
        return;
      }
      setConfig(editedConfig);
      setUnsavedChanges(false);
      setErrors([]);
      alert('Config updated successfully');
    } catch (error) {
      setErrors(['Failed to update config']);
    }
  };

  const handleGenerateConfig = async () => {
    if (!sampleDocs) {
      setErrors(['Sample documents cannot be empty']);
      return;
    }
    let docs = [];
    try {
      docs = JSON.parse(sampleDocs);
    } catch (error) {
      setErrors(['Failed to parse sample documents', error.message]);
      return;
    }
    if (!Array.isArray(docs)) {
      setErrors(['Sample documents must be an array']);
      return;
    }
    setIsGenerating(true);
    try {
      const data = await generateConfig(user, token, docs);
      if (data.status === "failure") {
        setErrors(
          data.errors
          ? data.errors.map((error) => {
              return `${error.field}: ${error.detail} (Location: ${error.locations})`;
            })
          : [data.message]);
        return;
      }
      setEditedConfig(data.config);
      setErrors([]);
      setActiveTab(1); // Switch to Edit tab
      setUnsavedChanges(true);
    } catch (error) {
      console.error(error);
      setErrors(['Failed to generate config']);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleConfigChange = (field, value) => {
    setEditedConfig(prev => ({
      ...prev,
      [field]: value
    }));
    setUnsavedChanges(true);
  };

  const handleCancelChanges = () => {
    setEditedConfig(JSON.parse(JSON.stringify(config))); // Deep copy to avoid reference issues
    setUnsavedChanges(false);
  };


  const handleFieldChange = (index, updatedField) => {
    const updatedFields = [...editedConfig.fields];
    updatedFields[index] = updatedField;
    handleConfigChange('fields', updatedFields);
  };

  const handleDeleteField = (index) => {
    const updatedFields = editedConfig.fields.filter((_, i) => i !== index);
    handleConfigChange('fields', updatedFields);
  };

  const handleAddField = () => {
    const newField = { name: '', type: 'text' };
    handleConfigChange('fields', [...editedConfig.fields, newField]);
  };

  const renderCurrentConfig = () => (
    <Box>
      <Typography variant="h6" gutterBottom>Current Configuration</Typography>
      {config && (
        <Box>
          <Typography variant="subtitle1" gutterBottom>Fields</Typography>
          <TableContainer component={Paper} sx={{ mb: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {config.fields.map((field, index) => (
                  <TableRow key={index}>
                    <TableCell>{field.name}</TableCell>
                    <TableCell>{field.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Box>
              <Typography variant="subtitle1" gutterBottom>Filters</Typography>
              <Paper elevation={1} sx={{ p: 1 }}>
                <List dense>
                  {config.filters.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={`${item.field} (${item.label})`} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>Sorts</Typography>
              <Paper elevation={1} sx={{ p: 1 }}>
                <List dense>
                  {config.sorts.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={`${item.field} (${item.asc_label}/${item.desc_label})`} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Box>
            <Box>
              <Typography variant="subtitle1" gutterBottom>Searchables</Typography>
              <Paper elevation={1} sx={{ p: 1 }}>
                <List dense>
                  {config.search.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Box>
          </Box>

          <Typography variant="subtitle1" gutterBottom>Group On</Typography>
          <Paper elevation={1} sx={{ p: 2 }}>
            <Typography>{config.group_on || 'None'}</Typography>
          </Paper>
        </Box>
      )}
    </Box>
  );

  const renderEditConfig = () => (
    <Box>
      <Typography variant="h6" gutterBottom>Edit Configuration</Typography>
      {unsavedChanges && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          You have unsaved changes. Don't forget to click "Update Config" to apply your changes.
        </Alert>
      )}
      <Typography variant="subtitle1" gutterBottom>Fields</Typography>
      {editedConfig.fields.map((field, index) => (
        <FieldComponent
          key={index}
          field={field}
          onFieldChange={(updatedField) => handleFieldChange(index, updatedField)}
          onDeleteField={() => handleDeleteField(index)}
        />
      ))}
      <Button
        variant="outlined"
        onClick={handleAddField}
        sx={{
          mb: 4,
          height: '56px', // Adjust this value based on the actual height of the TextField and Select components
          minWidth: '56px', // Ensures the button is square if that's desired
          padding: '6px 16px', // Adjust padding to match the TextField and Select components
        }}
        startIcon={<Add />}
      >
        Add Field
      </Button>

      <Box sx={{ mb: 2 }}>
        <FilterSelector
          label="Filters"
          fields={editedConfig.fields.map(f => f.name)}
          value={editedConfig.filters}
          onChange={(newFilters) => handleConfigChange('filters', newFilters)}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <SortSelector
          label="Sorts"
          fields={editedConfig.fields.map(f => f.name)}
          value={editedConfig.sorts}
          onChange={(newSorts) => handleConfigChange('sorts', newSorts)}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <MultiSelector
          label="Searchables"
          fields={editedConfig.fields.map(f => f.name)}
          value={editedConfig.search}
          onChange={(newSearch) => handleConfigChange('search', newSearch)}
        />
      </Box>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Group On</InputLabel>
        <Select
          value={editedConfig.group_on}
          onChange={(e) => handleConfigChange('group_on', e.target.value)}
          input={<OutlinedInput label="Group On" />}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {editedConfig.fields.map((field) => (
            <MenuItem key={field.name} value={field.name}>
              {field.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button
          variant="contained"
          onClick={handleUpdateConfig}
          disabled={!unsavedChanges}
        >
          Update Config
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancelChanges}
          disabled={!unsavedChanges}
        >
          Cancel Changes
        </Button>
      </Box>
    </Box>
  );

  const renderGenerateConfig = () => (
    <Box>
      <Typography variant="h6" gutterBottom>Generate New Configuration</Typography>
      <TextField
        fullWidth
        multiline
        rows={10}
        variant="outlined"
        label="Sample Documents (JSON)"
        value={sampleDocs}
        onChange={(e) => setSampleDocs(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        onClick={handleGenerateConfig}
        disabled={isGenerating}
      >
        {isGenerating ? <CircularProgress size={24} /> : 'Generate Config'}
      </Button>
    </Box>
  );

  const handleTabChange = (_, newValue) => {
    if (unsavedChanges) {
      setShowUnsavedChangesWarning(true);
    } else {
      setActiveTab(newValue);
    }
  };

  return (
    <Box sx={{ width: '100%', margin: 2 }}>
      <Typography variant="h4" gutterBottom>Config Manager</Typography>
      <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label="Current Config" />
        <Tab label="Edit Config" />
        <Tab label="Generate Config" />
      </Tabs>

      {errors.length > 0 && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errors.map((error, index) => (
            <Typography key={index}>{error}</Typography>
          ))}
        </Alert>
      )}

      {activeTab === 0 && renderCurrentConfig()}
      {activeTab === 1 && editedConfig && renderEditConfig()}
      {activeTab === 2 && renderGenerateConfig()}

      <Snackbar
        open={showUnsavedChangesWarning}
        autoHideDuration={6000}
        onClose={() => setShowUnsavedChangesWarning(false)}
        message="You have unsaved changes. Are you sure you want to leave this tab?"
        action={
          <>
            <Button color="secondary" size="small" onClick={() => {
              setShowUnsavedChangesWarning(false);
              setActiveTab(1); // Stay on Edit tab
            }}>
              Stay
            </Button>
            <Button color="primary" size="small" onClick={() => {
              setShowUnsavedChangesWarning(false);
              handleCancelChanges(); // Revert changes
              setActiveTab(activeTab === 0 ? 2 : 0); // Switch to the tab user wanted to go to
            }}>
              Leave
            </Button>
          </>
        }
      />
    </Box>
  );
};

export default ConfigManager;
